import React from 'react'
import {motion} from 'framer-motion'
import { images} from '../../perpetual'
import './Header.scss'
const Header = () => {

  //We added this project for motion.div variants.
  const scaleVariants = {
    whileInView: {
     scale: [0, 1],
     opacity:[0, 1],
     transition: {
      duration: 1,
      ease: 'easeInOut'
     }
    }
  }

  return (
    <div id="home" className='app__header app__flex'>
      {/* Make motion div for names and what you do etc */}

      <motion.div
      whileInView={{ x: [-100, 0], opacity:[0,1] }}
      transition={{ duration: 0.5}}
      className='app__header-info'
      >
        <div className='app__header-badge'>
          <div className='badge-cmp app__flex'>
            
            <div style={ {marginLeft: 10}}>
              <h1 className='head-text'>console.log <br/>("Hello World")</h1>
     
            </div>
          </div>
          <div className='tag-cmp app__flex'>
            <p className='p-text'>Software Developer</p>
            <p className='p-text'>Fullstack Developer </p>
          </div>
        </div>
      </motion.div>
{/* Another Motion that includes your profile pic etc will edit later */}
      <motion.div
        whileInView={{ opacity:[0,1] }}
        transition={{ duration: 0.5, delayChildren: 0.5}}
        className='app__header-img'
      >
         <img src={images.profile} alt='profile-pic'/>

{/* Another Motion that includes circle img */}
     
      </motion.div>

      <motion.div
        variants={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className='app__header-circles'
      >
         {[
              { image: images.github, link: 'https://github.com/batkhishig09' },
              { image: images.linkedin, link: 'https://www.linkedin.com/in/batkhishigchoi/' },
              { image: images.medium, link: 'https://medium.com/@batkhishig.09' },
              
         ].map((circle, index) => (
          <div className='circle-cmp app__flex' key={`circle-${index}`}>
             <a href={circle.link} target="_blank" rel="noopener noreferrer">
            <img src={circle.image} alt="circle" />
            </a>
          </div>
         ))}
       </motion.div>
    </div>
  )
}

export default Header
