import React, {useState, useEffect} from 'react'
import './About.scss'
import { motion } from 'framer-motion'
import { urlFor, client } from '../../client'
import Skills from '../Skills/Skills'

const About = () => {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
   const query = '*[_type == "abouts"]';
   client.fetch(query)
     .then((data) => {
      setAbouts(data);
     });

  }, [])
  
  return (
<div  className='app__profiles-flexbox' id='about'>
<h2 className='head-text' > Understanding that   <span>proficient development </span> <br/>leads to a <span>flourishing business</span></h2>
 <div className='app__profiles-bg'>

 <div className='app__profiles'>
  
  {abouts.map((about, index) => (
    <motion.div
     whileInView={{ opacity: 1 }}
     whileHover={{ scale: 1.1 }}
     transition={{ duration: 0.5, type: 'tween'}}
     className='app__profile-item'
     key={about.title + index}
    >
   <img src={urlFor(about.imgUrl)} alt={about.title} />
   <h2 className='bold-text' style={{ marginTop: 20}}>{about.title}</h2>
   <p className='p-text' style={{ marginTop: 20}}>{about.description}</p>
    </motion.div>
  ))}
 </div>
 <Skills />
 </div>
 </div>

  )
}

export default About
