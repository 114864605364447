// eslint-disable-next-line
import React, {useState, useEffect} from 'react'
import './Work.scss'
import {AiFillEye, AiFillGithub} from 'react-icons/ai'
import {motion} from 'framer-motion';
import {urlFor, client} from '../../client' 

const Work = () => {
  const [animateCart, setAnimateCart] = useState({y: 0, opacity: 1})
  const [filterWork, setFilterWork] = useState([]);
  
  useEffect(() => {
    const query = '*[_type == "works"]';
    
    client.fetch(query)
      .then((data) => {

        setFilterWork(data)
      })
  },[])
  

  return (
<>
<div className='app__work-bg' id='work'>
 <h2 className='head-text'>My Portfolio Sections</h2>

 <motion.div
 animate={animateCart}
 transition={{ duration: 0.5, delayChildren: 0.5}}
 className='app__work-portfolio'
 >
  {filterWork.map((work, index) => (
    <div className='app__work-item app__flex' key={index}>
      <div className='app__work-img app__flex'>
        <img src={urlFor(work.imgUrl)} alt={work.name} />
        <motion.div
        
        whileHover={{opacity: [0, 1]}}
        transition={{duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5}}
        className='app__work-hover app__flex'
        >
          <a href={work.projectLink} target='_blank' rel='noreferrer'>
          <motion.div
           whileInView={{scale: [0, 1]}}
           whileHover={{scale: [1, 0.9]}}
           transition={{duration: 0.25}}
           className='app__flex'
           >
          <AiFillEye/>
           </motion.div>
          </a>
          <a href={work.codeLink} target='_blank' rel='noreferrer'>
          <motion.div
           whileInView={{scale: [0, 1]}}
           whileHover={{scale: [1, 0.9]}}
           transition={{duration: 0.25}}
           className='app__flex'
           >
          <AiFillGithub/>
           </motion.div>
          </a>
        </motion.div>
      </div>
      <div className='app__work-content app__flex'>
  <h4 className='bold-text'>{work.title}</h4>
  <p className='p-text' style={{marginTop: 10}}>{work.description}</p>
  {work.tags && work.tags.length > 0 && (
    <div className='app__work-tag app__flex'>
      <p className='p-text'>{work.tags[0]}</p>
    </div>
  )}
</div>
    </div>
  ))}
 </motion.div>
 </div>
</>
  )
}

export default Work
