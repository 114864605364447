import React, {useState} from 'react'
import './Footer.scss'
import {images} from '../../perpetual'
import {client} from '../../client'

const Footer = () => {

const [formData, setFormData] = useState({name: '', email: '', message: ''});
const [isFormSubmitted, setIsFormSubmitted] = useState(false);
const [isLoading, setIsLoading] = useState(false);

const { name, email, message } = formData;

const handleChangeInput = (e) => {
  const { name, value } = e.target;

  setFormData({...formData, [name]: value })
}

const handleSubmit = () => {
  setIsLoading(true);

  const contact = {
    _type: 'contact',
    name: name,
    email: email,
    message: message,
  }

  client.create(contact)
  .then(() => {
    setIsLoading(false)
    setIsFormSubmitted(true);
  })

}
  return (

<div className='app__footer-bg' id='contact'>


<h2 className='head-text'>Connect with me</h2>
<div className='app__footer-cards'>
   <div className='app__footer-card'>

    <img src={images.email} alt="email"/>
    <a href='mailto:batkhishig09@gmail.com' className='p-text'>batkhishig09@gmail.com</a>
   </div>

   <div className='app__footer-card'>
    <img src={images.call} alt="email"/>
    <a href='tel: +1 (132) 843-9389' className='p-text'>+1 (132) 843-9389</a>
   </div>
</div>

{!isFormSubmitted ? 
  <div className='app__footer-form app__flex'>
  <div className='app__flex'>
    <input className='p-text' type='text' placeholder='Your Name' name="name" value={name} onChange={handleChangeInput}/>
  </div>
  <div className='app__flex'>
    <input className='p-text' type='email' placeholder='Your Email' name="email" value={email} onChange={handleChangeInput}/>
  </div>
  <div>
    <textarea className='p-text' placeholder='Your Message' value={message} name='message' onChange={handleChangeInput}/>
  </div>
  <button type='button' className='p-text' onClick={handleSubmit}> {isLoading ? 'Sending' : 'Send Message'} </button>
</div> : <div>
  <h3 className='head-text'>Thank you for getting in touch with me!</h3>
  </div>}
  </div>

  )
}

export default Footer
